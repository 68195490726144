import {TableStore} from 'app/store/TableStore';
import {UserStore} from 'app/store/UserStore';
import {CurrentTimeStore} from 'stores/current_time_store';
import Line from './Line';
import Get from 'app/utils/Get';
import {WHITE} from 'app/lines/line_helpers';
import LineInterface from 'app/interfaces/LineInterface';
import {isNestedLine} from 'app/lines/isNestedLine';
import {isMainLine} from 'app/lines/isMainLine';

type UserBetsType = {
    on: Map<number, Array<number>>
    root: Array<number>
};

interface LinesServiceConstructor {
    url: string
    userStore: UserStore
    currentTimeStore: CurrentTimeStore
}

export type Lines = Record<number, Line>;
type Callback = (parents: Lines, results: boolean) => void;

interface ApiResponse {
    bets: LineInterface[],
    nested_bets: LineInterface[]
    user_bets: UserBetsType
    user_time: number
    ut: number
}

export default class LinesService {
    constructor({url, userStore, currentTimeStore}: LinesServiceConstructor) {
        this.url = url;
        this.userStore = userStore;
        this.currentTimeStore = currentTimeStore;
    }

    userStore: UserStore;

    tableStore: TableStore;

    currentTimeStore: CurrentTimeStore;

    url: string;

    parents: Lines = {};

    results_parents: Lines = {};

    user_bets: UserBetsType = {on: {} as UserBetsType['on'], root: []};

    update_time = 0;

    user_time = 0;

    setBets(newLines: LineInterface[], results: boolean): void {
        newLines.forEach(newLine => {
            if (this.update_time < newLine.ut) {
                this.update_time = newLine.ut;
            }
            this.setBet(newLine, results);
        });
    }

    setBet(newLine: LineInterface, results: boolean): void {
        const oldLine = this.findOldLine(newLine, results);

        if (newLine.color === WHITE && !results && !isNestedLine(newLine)) {
            this.deleteMainLine(newLine, false);
        } else if (newLine.color !== WHITE && results) {
            this.deleteMainLine(newLine, true);
        } else if (oldLine) {
            if (this.canDeleteLine(newLine)) {
                this.deleteOldLine(newLine, results);
            } else {
                oldLine.update(newLine);
            }
        } else if (!this.canDeleteLine(newLine)) {
            this.createNewLine(newLine, results);
        }
    }

    // eslint-disable-next-line max-params,max-statements
    updateBetsHandler(data: ApiResponse, callback: Callback, results = false, forceUpdate = false): void {
        this.currentTimeStore.setTime(data.user_time);
        this.user_time = data.user_time;

        let update = this.update_time > 0;

        if (data.ut === 0 && !forceUpdate) {
            update = false;
            const parents = this.getParents(results);
            const newParents = {};

            if (this.userStore.user.light_mode) {
                this.tableStore.openedLines.forEach(id => {
                    if (parents[id]) {
                        newParents[id] = parents[id];
                    }
                });
            }
            this.setParents(newParents, results);
        }
        if (data.bets) {
            this.setBets(data.bets, results);
        }
        if (data.nested_bets) {
            this.setBets(data.nested_bets, results);
        }
        if (data.user_bets) {
            this.user_bets = data.user_bets;
        }
        if (!callback) {
            return;
        }
        callback(this.getParents(results), results);

        if (this.userStore.user.light_mode && !update) {
            this.tableStore.clearLoadedLinesIds();
            if (this.tableStore.openedLines.length > 0) {
                this.update_line(this.tableStore.openedLines);
            }
        }
    }

    update_bets(callback: Callback, results = false, allLines = false): Promise<void> {
        return new Get({params: {
            active: !results,
            st: this.user_time,
            ut: allLines ? 0 : this.update_time
        },
        url: this.url})
            .execute()
            .then(response => response.json())
            .then(data => this.updateBetsHandler(data, callback, results));
    }

    update_line(line_id: number | number[], callback?: Callback, results = false): Promise<void> {
        if (!Array.isArray(line_id) && !this.getParents(results)[line_id]) {
            return Promise.resolve();
        }

        return new Get({
            params: {
                line_id,
                st: this.user_time,
                ut: this.update_time
            },
            url: this.url
        })
            .execute()
            .then((response): Promise<ApiResponse> => response.json())
            .then(data => {
                this.tableStore.addLoadedLineId(line_id);
                this.updateBetsHandler(data, callback, results, true);
            });
    }

    listen(payload: LineInterface): void {
        this.setBet(payload, false);
        this.tableStore.bets = [...this.tableStore.bets];
    }

    private canDeleteLine = (line: LineInterface): boolean => line.deleted || line.hidden;

    private findOldLine = (line: LineInterface, results: boolean): Line => {
        const rootLine = this.getParents(results)[line.root_line_id];

        if (!rootLine || isMainLine(line)) {
            return rootLine;
        }
        return rootLine.allNestedLines[line.id];
    };

    private createNewLine = (line: LineInterface, results: boolean): void => {
        if (isMainLine(line)) {
            this.getParents(results)[line.id] = new Line(line);
            return;
        }

        const rootLine = this.findRootLine(line, results);

        if (!rootLine) {
            return;
        }
        this.createNestedLine(line, rootLine);
    };

    private deleteOldLine = (line: LineInterface, results: boolean): void => {
        this.tableStore.removeLoadedLine(line.root_line_id);

        const rootLine = this.findRootLine(line, results);

        if (!rootLine) {
            return;
        }

        if (isMainLine(line)) {
            delete this.getParents(results)[line.id];
            return;
        }
        this.deleteNestedLine(line, rootLine);
    };

    private deleteMainLine = (line: LineInterface, results: boolean): void => {
        this.tableStore.removeLoadedLine(line.root_line_id);
        delete this.getParents(results)[line.id];
    };

    private getParents = results => results ? this.results_parents : this.parents;

    private setParents(newParents, results) {
        if (results) {
            this.results_parents = newParents;
        } else {
            this.parents = newParents;
        }
    }

    private findRootLine = (line: LineInterface, results: boolean): Line => this.getParents(
        results
    )[line.root_line_id];

    private createNestedLine = (line: LineInterface, rootLine: Line): void => {
        const parent = this.findParent(line, rootLine);
        const res = new Line(line, parent) as unknown as LineInterface;
        parent.nested_bets[line.id] = res;
        rootLine.allNestedLines[line.id] = res;
    };

    private deleteNestedLine = (line: LineInterface, rootLine: Line): void => {
        const parent = this.findParent(line, rootLine);
        delete parent.nested_bets[line.id];
        delete rootLine.allNestedLines[line.id];
    };

    private findParent = (line: LineInterface, rootLine: Line): LineInterface => {
        let parent = rootLine.allNestedLines[line.game_id];
        if (!parent) {
            parent = rootLine;
        }
        return parent;
    };
}
