import Websocket from 'modules/Websocket';

import {
    giveawayStore,
    promoActionStore,
    storiesStore,
    streamStore,
    userStore,
    casinoGamesStore,
    articlesStore,
    userBonusesStore, cashoutsStore, steamTradeStore, depositsStore, linesService
} from 'app/store/global';
import UserInterface from 'app/interfaces/UserInterface';
import {isGuest} from 'app/utils';
import NotificationStore from 'app/store/notifications';
import {LinePayloadInterface} from 'app/interfaces/LinePayloadInterface';
import {DelayedBetCancelledInterface} from 'app/interfaces/DelayedBetCancelledInterface';
import {PokerTablePayLoadInterface} from 'app/interfaces/stores/subcribe/PokerTablePayLoadInterface';
import {PokerTablesPayLoadInterface} from 'app/interfaces/stores/subcribe/PokerTablesPayLoadInterface';

export interface DataEventInterface {
    data: {
        event: string
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        payload: any
        type: string
        group: string
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data: any
    }
}

export const unsubscribe = (subscription: string): null => {
    if (subscription) {
        Websocket.unsubscribe(subscription);
    }
    return null;
};

export const listenGeneral = (): void => {
    subscribeGeneral(({data: {event, payload}}) => {
        switch (event) {
        case 'lines_update_coef':
            linesService.listen(payload);
            break;
        case 'giveaways_update':
            giveawayStore.updateGiveaway(payload);
            break;
        case 'main_articles_update':
            articlesStore.listenMain(payload);
            break;
        case 'promo_new_articles_update':
            articlesStore.setPromoNewData(payload);
            break;
        case 'promo_actions_update':
            promoActionStore.update(payload);
            break;
        case 'stories_update':
            storiesStore.listenUpdate(payload);
            break;
        case 'stories_delete':
            storiesStore.deleteStory(payload.id);
            break;
        case 'streams_update':
            streamStore.add(payload);
            break;
        case 'streams_delete':
            streamStore.delete(payload.id);
            break;
        default: break;
        }
    });
};

export const listenUser = (): void => {
    if (isGuest()) {
        return;
    }

    subscribeUser(userStore.user, ({data: {event, payload}}) => {
        switch (event) {
        case 'story_update':
            storiesStore.listenUserStory(payload);
            break;
        case 'story_image_update':
            storiesStore.updateUserStoryImage(payload);
            break;
        case 'casino_games_update':
            casinoGamesStore.listenCasinoGameUpdate(payload);
            break;
        case 'casino_session_update':
            casinoGamesStore.listenCasinoSession(payload);
            break;
        case 'giveaway_update':
            giveawayStore.listenUserGiveaway(payload);
            break;
        case 'bonus_admin_cancel':
            userBonusesStore.listen();
            break;
        case 'bonus_create':
            userBonusesStore.listen();
            break;
        case 'promo_action_update':
            promoActionStore.update(payload);
            break;
        case 'user_update':
            userStore.listen(payload);
            break;
        case 'cashout_update':
            cashoutsStore.updateCashout(payload);
            break;
        case 'steam_trade_update':
            steamTradeStore.updateElementCollection(payload);
            break;
        case 'ofelospay_deposit_update':
            depositsStore.updateCurrentOfelospayDeposit(payload);
            break;
        default: break;
        }
    });
};

export const listenNotifications = (): void => {
    subscribeNotifications(({data}) => {
        NotificationStore.listen(data);
    });
};

export const listenUserNotifications = (): void => {
    if (isGuest()) {
        return;
    }

    subscribeUserNotification(userStore.user, ({data}) => {
        NotificationStore.listen(data);
    });
};

export const subscribeGeneral = (onEvent: (data: DataEventInterface) => void):
    string => Websocket.subscribe(':general', onEvent);

export const subscribeUser = (user: UserInterface, onEvent: (data: DataEventInterface) => void):
    string => Websocket.subscribe(`$user-${user.id}`, onEvent);

export const subscribeNotifications = (onEvent: (data: DataEventInterface) => void):
    string => Websocket.subscribe(':notifications', onEvent);

export const subscribeUserNotification = (user: UserInterface, onEvent: (data: DataEventInterface) => void):
    string => Websocket.subscribe(`$notification-${user.id}`, onEvent);

export const subscribeCryptoAddress = (user: UserInterface, onEvent: (data: DataEventInterface) => void):
    string => Websocket.subscribe(`$crypto-address-${user.id}`, onEvent);

export const subscribeLineUpdateCoef = (hash_id: string, onEvent: (data: LinePayloadInterface) => void):
    string => Websocket.subscribe(`:line-${hash_id}`, onEvent);

export const subscribeDelayedBet = (userId: number, onEvent: (data: DelayedBetCancelledInterface) => void):
    string => Websocket.subscribe(`$bet-of-${userId}`, onEvent);

export const subscribePokerTable = (userId: number, onEvent: (data: PokerTablePayLoadInterface) => void):
    string => Websocket.subscribe(`$poker-table-${userId}`, onEvent);

export const subcribePokerTables = (onEvent: (data: PokerTablesPayLoadInterface) => void):
    string => Websocket.subscribe(':poker_tables', onEvent);
