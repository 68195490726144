import React from 'react';
import Chat from 'app/components/Chat';
import MainPageDescription from 'app/components/MainPageDescription';
import SiteVersion from '../SiteVersion';
import SupportButton from 'app/components/SupportButton';
import {isDesktop} from 'app/utils';
import SmartStoriesMobile from 'app/components/pages/home_page/SmartStories/SmartStoriesMobile';
import AsideStories from 'site_version/com/AsideStories';
import Giveaway from 'site_version/com/Giveaway';
import SocialsList from '../common/aside/SocialsList';
import Apps from '../common/aside/Apps';
import banners from 'site_version/com/aside/Banners';

const Aside = ({showWhyEgb, hideChat}) => <aside className="aside">
    <div className="aside__inner">
        {!isDesktop() && <SmartStoriesMobile/>}

        {isDesktop() && <AsideStories/>}

        {!hideChat && <Chat/>}
        <div className="support">
            <SupportButton/>
        </div>

        <SocialsList/>

        <Giveaway itemClassName="giveaway"/>

        {!window.SITE_DATA.MOBILE_WRAPPER && <Apps/>}

        {showWhyEgb && <MainPageDescription/>}

        {banners()}
    </div>

</aside>;

SiteVersion.Aside = Aside;
