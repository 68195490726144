import LineInterface from 'app/interfaces/LineInterface';
import {lineGamer} from 'app/lines/lineGamer';
import {isAdvantageLine} from 'app/lines/isAdvantageLine';
import i18n from 'app/i18n';
import {isHandicapLine} from './isHandicapLine';

export const tableNick = (line: LineInterface, gamerNum: number): string => {
    if (isAdvantageLine(line)) {
        return line[`gamer_short_text_${gamerNum}`];
    }
    if (line.type_map || isHandicapLine(line)) {
        const translatedMap = i18n.t('map');
        const name = lineGamer(line, gamerNum).nick;
        return name.replace(/Map|Карта/u, translatedMap);
    }

    return lineGamer(line, gamerNum).nick;
};
