import 'app/interfaces/WindowInterface';
import 'modules/apm_rum';
import 'lazysizes';
import '../polyfill';
import 'moment';
import 'moment-timezone';
import 'bootstrap';
import 'perfect-scrollbar.min';
import 'slick.min';
import 'resizable';
import {
    articleStore,
    artifactStore,
    buyOrderStore,
    cashoutsStore,
    p2pDepositsStore,
    expressBetStore,
    giveawayStore,
    notificationSettingsStore,
    orderStore,
    pageContentStore,
    promoActionStore,
    recipeStore,
    screenStore,
    sellOrderStore,
    shopLootboxStore,
    otherItemStore,
    siteNotificationStore,
    smileStore,
    steamTradeStore,
    storiesStore,
    streamStore,
    tableStore,
    userBetsStore,
    userStore,
    casinoGamesStore,
    userCasinoLogStore,
    articlesStore,
    chatStore,
    activeSurveyStore,
    shopStore,
    betSlipStore,
    artifactBetStore,
    incubatorStore,
    gamesStore,
    incubatorFilterStore,
    betsFilterStore,
    resultsFilterStore,
    incubatorShiftStore,
    incubatorUserLinesStore,
    flagsStore,
    userBonusesStore,
    modalsStore,
    faqStore,
    pokerStore
} from 'app/store/global';
import {isDesktopMedia, isGuest, userSignedIn} from 'app/utils';
import React, {Fragment} from 'react';
import ReactDOM from 'react-dom/client';
import {init} from 'app/common';
import 'app/firebase';
import {configure} from 'mobx';
import {Provider} from 'mobx-react';
import Subscription from 'app/components/informers/subscription';
import {currentTimeStore} from 'stores/global';
import {importSiteVersion} from 'site_version/SiteVersion';
import NotificationStore from 'app/store/notifications';
import {amplitudeInit} from 'app/amplitude/amplitude';
import {getLanguageStorage, setLanguage} from 'app/utils/Language';
import {RouterProvider} from 'react-router-dom';
import DepositsStore from 'app/store/DepositsStore';
import {router} from 'app/components/routes/router';
import {sendError} from 'app/utils/error/sendError';

configure({enforceActions: 'always'});

articleStore.init({pinnedPromotionsCount: window.SITE_DATA.PINNED_PROMOTIONS_COUNT});
recipeStore.updateRecipes(window.SITE_DATA.MARKETPLACE_DATA);
recipeStore.updateRecipes(window.SITE_DATA.MARKET_PRICES);
orderStore.updateOrders(window.SITE_DATA.ARTIFACT_ORDERS_DATA);
pageContentStore.initUpdate(window.SITE_DATA.PAGE_CONTENT);
currentTimeStore.setTime(window.settings.user.time_zone.t);
streamStore.init(window.STREAMS.streams, window.STREAMS_TOURNAMENT_NAME, window.STREAMS.streams_by_games);
shopLootboxStore.updateLootbox({lootbox: window.SITE_DATA.SHOP_LOOTBOX});
siteNotificationStore.init(window.SITE_DATA.SITE_NOTIFICATIONS);
promoActionStore.update(window.SITE_DATA.PROMO_ACTIONS);
notificationSettingsStore.init(window.SITE_DATA.NOTIFICATION_SETTINGS);
cashoutsStore.update({}, window.SITE_DATA.P2P_CASHOUTS_IN_WAITING);
screenStore.setDesktop(isDesktopMedia());
giveawayStore.init(window.SITE_DATA.GIVEAWAY);
chatStore.init();
activeSurveyStore.init(window.SITE_DATA.ACTIVE_SURVEY);
shopStore.init();
incubatorShiftStore.init(window.SITE_DATA.INCUBATOR_SHIFTS);
flagsStore.init(window.REG_FLAGS);
modalsStore.init([]);

const stores = {
    NotificationStore,
    activeSurveyStore,
    articleStore,
    articlesStore,
    artifactBetStore,
    artifactStore,
    betSlipStore,
    betsFilterStore,
    buyOrderStore,
    cashoutsStore,
    casinoGamesStore,
    chatStore,
    currentTimeStore,
    depositsStore: new DepositsStore(),
    expressBetStore,
    faqStore,
    gamesStore,
    giveawayStore,
    incubatorFilterStore,
    incubatorShiftStore,
    incubatorStore,
    incubatorUserLinesStore,
    modalsStore,
    notificationSettingsStore,
    orderStore,
    otherItemStore,
    p2pDepositsStore,
    pageContentStore,
    pokerStore,
    promoActionStore,
    recipeStore,
    resultsFilterStore,
    screenStore,
    sellOrderStore,
    shopLootboxStore,
    shopStore,
    siteNotificationStore,
    smileStore,
    steamTradeStore,
    storiesStore,
    streamStore,
    tableStore,
    userBetsStore,
    userBonusesStore,
    userCasinoLogStore,
    userStore
};

if (isGuest() || userSignedIn() && window.settings.user.country_code === 'xx') {
    const flag = window.REG_FLAGS.find(x => x.code === window.CURRENT_IP_COUNTRY);

    if (flag) {
        window.CURRENT_COUNTRY = flag.code;
        window.SIGN_UP_USER.country = flag.code;
    }
}

userStore.setSignUpCountry(window.SIGN_UP_USER.country);

const language = getLanguageStorage();

if (language !== window.CURRENT_LOCALE) {
    setLanguage(language);
}

const getTranslations = async(lang, callback) => {
    await importSiteVersion();

    callback();
};

// eslint-disable-next-line max-params
window.onerror = (message, file, line, column, errorObject) => {
    const data = {
        column: column || window.event && window.event.errorCharacter,
        errorStack: errorObject ? errorObject.stack : null,
        file,
        line,
        message
    };

    if (message === 'Script error.' || message.includes('status code 429')) {
        return false;
    }

    sendError(data);
    return false;
};

document.addEventListener('DOMContentLoaded', () => {
    getTranslations(window.CURRENT_LOCALE, () => {
        amplitudeInit();
        init();
        ReactDOM.createRoot(document.getElementById('app')).render(
            <Provider {...stores}>
                <Fragment>
                    <RouterProvider router={router}/>
                </Fragment>
            </Provider>
        );
        Subscription.inform();
    }).catch(reason => {
        throw reason;
    });
});
