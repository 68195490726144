import React, {Component, ReactNode} from 'react';
import Draggable from 'react-draggable';
import PokerMenuContainer from 'app/components/poker/poker_menu_container/PokerMenuContainer';
import PokerMoveButtonDraggable from 'app/components/poker/poker_move_buttons/PokerMoveButtonDraggable';
import PokerMoveButtonMinimize from 'app/components/poker/poker_move_buttons/PokerMoveButtonMinimize';
import PokerRoom from 'app/components/poker/poker_room/PokerRoom';
import PokerPlayersAmount from 'app/components/poker/poker_players_amount/PokerPlayersAmount';
import {inject, observer, Provider} from 'mobx-react';
import {PokerStore} from 'app/store/PokerStore';
import {PokerController} from 'app/controllers/PokerController';

interface Props {
    pokerStore?: PokerStore
}

@inject('pokerStore')
@observer
export default class PokerContainer extends Component<Props> {
    controller: PokerController;

    constructor(props: Props) {
        super(props);
        this.controller = new PokerController(this.props.pokerStore);
    }

    render(): ReactNode {
        const {pokerStore} = this.props;

        if (!pokerStore.currentTable) {
            return <></>;
        }

        const {count_players} = pokerStore.currentTable;

        return <Provider controller={this.controller}>
            <Draggable defaultPosition={{x: 30, y: 40}} handle={'.poker-move-button'}>
                <div className="poker-draggable">
                    <div className="poker">
                        <div className="poker__header">
                            <PokerMenuContainer />
                            <div className="poker__header-right">
                                <PokerPlayersAmount currentCount={count_players} />
                                <PokerMoveButtonDraggable/>
                                <PokerMoveButtonMinimize/>
                            </div>
                        </div>
                        <div className="poker__body">
                            <PokerRoom/>
                        </div>
                    </div>
                </div>
            </Draggable>
        </Provider>;
    }
}
