import {BaseController} from 'app/controllers/BaseController';
import Post from 'app/utils/Post';
import {action} from 'mobx';
import {PokerStore} from 'app/store/PokerStore';
import {toastError} from 'app/components/toasts/liteToast';

export class PokerController extends BaseController {
    store: PokerStore;

    constructor(store: PokerStore) {
        super();
        this.store = store;
    }

    @action
    sitIn(table_id: string): void {
        new Post({
            url: `/poker/tables/${table_id}/sit_in`
        }).execute()
            .then(response => response.json())
            .then(response => {
                if (response.success) {
                    this.store.updateCurrentTable(response.table);
                } else {
                    toastError(response.message);
                }
            });
    }

    @action
    check(table_id: string): void {
        new Post({
            url: `/poker/tables/${table_id}/check`
        }).execute()
            .then(response => response.json())
            .then(response => {
                if (response.success) {
                    this.store.updateCurrentTable(response.table);
                } else {
                    toastError(response.message);
                }
            });
    }

    @action
    call(table_id: string): void {
        new Post({
            url: `/poker/tables/${table_id}/call`
        }).execute()
            .then(response => response.json())
            .then(response => {
                if (response.success) {
                    this.store.updateCurrentTable(response.table);
                } else {
                    toastError(response.message);
                }
            });
    }

    @action
    loadTable = (table_id: string): void => {
        new Post({
            params: {id: table_id},
            url: '/poker/tables'
        }).execute()
            .then(response => response.json())
            .then(response => {
                this.store.updateCurrentTable(response);
            });
    };
}
