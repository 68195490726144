import React, {ReactNode} from 'react';
import PokerButtonTakePlace from 'app/components/poker/poker_players/poker_take_place/PokerButtonTakePlace';
import PokerDealer from 'app/components/poker/poker_players/poker_dealer/PokerDealer';
import PlayerInterface from 'app/interfaces/poker/PlayerInterface';
import {selectCard} from 'app/utils/poker/selectCard';

interface Props {
    position: number
    isBigBlind: boolean
    active?: boolean
    player?: PlayerInterface
    onSitIn?: () => void
}

const HALF_OF_PLAYERS = 3;

export default class PokerPlace extends React.Component<Props> {
    render(): ReactNode {
        const {position, player, isBigBlind, onSitIn, active} = this.props;
        const bet_position = position <= HALF_OF_PLAYERS ? 'bet-bottom' : 'bet-top';
        const activeClassName = active ? ' active' : '';

        return <div className={`poker-player place-${position} ${bet_position}${activeClassName}`}>
            <PokerButtonTakePlace onSitIn={onSitIn}/>
            {player && <>
                <div className="player-cards">
                    {player.cards
                        ? <>{player.cards.map(c => selectCard(c))}</>
                        : <>
                            <span className="player-card-first"></span>
                            <span className="player-card-second"></span>
                        </>}
                </div>
                <div className="poker-player-about my-player">
                    <span className="name" title={player.name}>{player.name}</span>
                    <span className="deposit">${player.bank}</span>
                </div>
                {player.bet && <div className="poker-player-bet">
                    <span className="amount">${player.bet}</span>
                </div>}
                {isBigBlind && <PokerDealer/>}
            </>}
        </div>;
    }
}
