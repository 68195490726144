import {inject, observer} from 'mobx-react';
import React, {ReactNode} from 'react';
import Row from 'app/components/table/row/index';
import LineInterface from 'app/interfaces/LineInterface';
import {TableStore} from 'app/store/TableStore';
import {UserStore} from 'app/store/UserStore';
import {isRootLine} from 'app/utils/line/rootLine';
import {gameImage} from 'app/utils';
import GroupContainer from './GroupContainer';
import I18n from 'app/i18n';
import {WHITE, lineGamer} from 'app/lines/line_helpers';
import {ActiveCoef} from 'app/lines/createSerialRendering';
import {userStore} from 'app/store/global';

interface MapWithNestedLinesInterface extends LineInterface {
    active_nested_bets_count: number
}

export interface MapWithNestedLinesProps {
    nestedLines: MapWithNestedLinesInterface[]
    bet: MapWithNestedLinesInterface;
    activeCoef: ActiveCoef;
    coefOnClick: number
    isExpress: boolean
    seo: string
    tableStore: TableStore
    userStore: UserStore
    isNestedBet: boolean
    show: boolean
}

interface GroupedData {
    [key: string]: LineInterface[];
}

const DEFAULT_HANDICAP = 'handicap_0';

@inject('tableStore', 'userStore')
@observer

export default class MapWithNestedLines extends Row {
    props: MapWithNestedLinesProps;

    constructor(props: MapWithNestedLinesProps) {
        super(props);
        this.state = {
            changeClass: false,
            loading: false,
            showModal: false,
            showNestedBets: false,
            widgetUrl: ''
        };
    }

    componentDidMount(): void {
        if (this.props.show) {
            this.setState({showNestedBets: true});
        }
    }

    nestedLineWithoutNestedToNested(nestedBets: LineInterface[]): boolean {
        if (isRootLine(this.props.bet)) {
            return nestedBets.filter(line => line.nested_bets_count > 0).length === 0;
        }
        return false;
    }

    handleChildStateChange = (openned?: boolean): void => {
        this.setState(prevState => {
            const shouldAddClass = openned;

            if (prevState.addClass !== shouldAddClass) {
                return {addClass: shouldAddClass};
            }

            return null;
        });
    };

    renderTableExpand = (): JSX.Element => <span className="table-expand table-expand--inner"/>;

    renderGameImage(): ReactNode {
        const {isNestedBet, bet} = this.props;
        return isNestedBet ? null : gameImage(bet.filter);
    }

    groupByType(): GroupedData {
        return this.nestedBets().reduce((acc, item) => {
            const gameInfo = lineGamer(item, 1);

            const key = item.type === 'Handicap' || item.type === 'NestedBet'
                ? gameInfo.langs && gameInfo.langs[userStore.user.site_lang] || gameInfo.game_name
                : item.type;

            if (!acc[key]) {
                acc[key] = [];
            }

            if (!item.hide && (item.color === WHITE && this.props.tableStore.results || item.color !== WHITE)) {
                acc[key].push(item);
            }

            return acc;
        }, {} as GroupedData);
    }

    render(): JSX.Element {
        const showNestedBets = true;
        const nestedBets = this.nestedBets();
        const {bet, bet: {color, has_advantage, nested_lines_count, hash_id}, isNestedBet} = this.props;
        const rowClass = `table-bets__${isNestedBet ? 'inner1-row' : 'main-row'}
    js-expand-row${showNestedBets ? ' main-row-openned' : ''} has-already-bet`;
        const colorClass = `table-bets__${isNestedBet ? 'inner-row' : 'main-row' }-holder
    holder--color${color}${has_advantage ? ' advantage' : ''}`;
        const groupedData = this.groupByType();

        return <div id={`line-row-${hash_id}`} className={rowClass}>
            {isNestedBet
                ? <div className={colorClass}>
                    <div className={`table-bets__content
                    ${isNestedBet ? 'nested' : ''} table-bets__border-bottom`}>
                        {this.renderButtonPlus('table-expand--main', nested_lines_count, nested_lines_count >= 1)}
                        {this.renderBody(true)}
                    </div>
                </div>
                : <div className={colorClass}>
                    {this.renderButtonPlus('table-expand--main', nested_lines_count, nested_lines_count >= 1)}
                    {this.renderBody()}
                </div>}
            {nestedBets.length > 0 && <div
                className={`table-bets__main-row-container main-group-rows ${this.nestedLineWithoutNestedToNested(nestedBets)
                    ? 'first-line'
                    : 'no_first_nested'} ${this.state.addClass
                    ? 'openned'
                    : ''}`}
                ref={this.container}
            >
                {groupedData && Object.entries(groupedData).map(([key, items], index) => items.length >= 1
                    ? <GroupContainer
                        key={`nested-line-${index}`}
                        nestedLines={items}
                        showNestedBets={showNestedBets}
                        bet={bet}
                        name={key === 'null' ? I18n.t(DEFAULT_HANDICAP) : key}
                        handleChildStateChange={this.handleChildStateChange}
                        {...this.props}
                    />
                    : null)}
            </div>}
        </div>;
    }
}
